import * as React from "react";
import "./footernormal.scss"
import Image from "../Elements/Image";

interface State {
}

interface Props {
}

class Index extends React.PureComponent<Props, State> {
    componentDidMount() {
        // let pre_script = document.querySelector('#ebsgovicon');
        // if (pre_script) {
        //     console.log("之前已经加载Js")
        //     return;
        // }
        //
        // let script = document.createElement("script");
        // script.id = "ebsgovicon";
        // script.type = "text/javascript";
        // script.async = true;
        // script.src = "https://szcert.ebs.org.cn/govicons.js?id=beb368e4-6eeb-4968-908b-5a05333aa576&width=36&height=50&type=1";
        // let ebs_gov_link = document.getElementById("ebs_gov_link");
        // console.log("------------------------");
        // console.log(ebs_gov_link);
        // if (ebs_gov_link) {
        //     ebs_gov_link.appendChild(script);
        // }
    }

    render() {
        return <footer className="container-wrap bg-black">
            <div className="container footer-container clearfix">
                <div className="footer-row row-about">
                    <p className="row-a-line"><i className="iconfont icon-weizhi"></i>深圳福田区八卦三路88号荣生大厦802室</p>
                    <p className="row-a-line"><i className="iconfont icon-dianhua"></i>会员服务热线：40076-23366</p>
                    <p className="row-a-line"><i className="iconfont icon-xinxiduanxinxiaoxitixingyoujiansixinyouxiang"></i>sztaxton@sztaxton.com</p>
                    <p className="row-a-line"><i className="iconfont icon-f-74"></i>0755-25911594 (传真)</p>
                    <p className="row-a-line"><i className="iconfont icon-zhanghu"></i>银行账户：深圳市中税华通信息咨询有限公司</p>
                    <p className="row-a-line"><i className="iconfont icon-yinhang"></i>开户银行：中国银行彩虹支行</p>
                    <p className="row-a-line"><i className="iconfont icon-qiapian3"></i>银行账号：7458 6757 9094</p>
                </div>
                <div className="footer-row row-info">
                    <div className="row-info-main">
                        <Image src={require("../../assets/logo-foot.png")} alt="logo" className="r-info-logo"/>
                        <p className="r-info-desc">中税华通是以软件研发、涉税软件IT服务、财税业务咨询为主的综合型公司；凭借雄厚的研发实力、涉税软件产品及会员服务体系，以“信息化+内容服务专家”为企业目标，做纳税人信赖的综合财税服务平台。</p>
                        <div className="r-info-w-box">
                            <div className="r-info-w">
                                <Image src={require("../../assets/wxgzh.jpg")} alt="公众号"/>
                                <p className="r-info-text">官方公众号</p>
                            </div>
                            <div className="r-info-w">
                                <Image src={require("../../assets/server-ewm.png")} alt="公众号"/>
                                <p className="r-info-text">微信客服</p>
                            </div>
                            {/* <p className="r-info-w-name">官网公众号</p> */}
                        </div>
                    </div>
                </div>
                <div className="footer-row row-links">
                    <div className="row-links-head">友情链接</div>
                    <ul className="row-f-link">
                        <li><a target="_blank" rel="noopener noreferrer" href="https://fpdk.shenzhen.chinatax.gov.cn"><p><i className="iconfont icon-next"></i>增值税发票选择确认平台</p></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="http://hrss.sz.gov.cn/szsi/"><p><i className="iconfont icon-next"></i>深圳市社会保险基金</p></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="http://amr.sz.gov.cn"><p><i className="iconfont icon-next"></i>深圳市场监督管理局</p></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="http://www.gsxt.gov.cn"><p><i className="iconfont icon-next"></i>企业信息公示系统</p></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="http://www.sz.gov.cn/cn"><p><i className="iconfont icon-next"></i>深圳政府在线</p></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="http://www.chinatax.gov.cn"><p><i className="iconfont icon-next"></i>国家税务总局</p></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://shenzhen.chinatax.gov.cn"><p><i className="iconfont icon-next"></i>深圳税务局</p></a></li>

                    </ul>
                </div>
            </div>
            <div className="container-wrap container-copyright bg-dark">
                <div className="container clearfix">
                    <div className="copyright-t copyright-l">Copyright &copy; 2019 深圳市中税华通信息咨询有限公司.</div>
                    <div className="copyright-r copyright-t">
                        <a className="copyright-t" href="http://www.beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">粤ICP备09213309-1号</a>|
                        <a className="copyright-t" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402000410" target="_blank" rel="noopener noreferrer">粤公网安备 44030402000410号</a>
                    </div>
                </div>
            </div>
        </footer>
    }
}

export default Index
